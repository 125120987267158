import useGraphql from "@/composables/useGraphql";
import * as cartQueries from "@/graphql/queries/cart";
import * as commonQueries from "@/graphql/queries/common";

import { defineStore, acceptHMRUpdate } from "pinia";
import { useAuthStore } from "@/stores/auth";
import { useOrderStore } from "@/stores/order";
import { useGlobalStore } from "@/stores/global";

import {
  CartInput,
  ProductInput,
  SubsOrderDataInput,
  SpecifyDeliveryDateInput,
} from "@/types/input/CartInput";
import { CustomersCustomerType } from "@/types/customers/CustomersCustomerType";
import { CustomersShippingAddressType } from "@/types/customers/CustomersShippingAddressType";
import {
  CustomersCardType,
  CustomersPayjpCardType,
  CustomersGmoPgCardType,
} from "@/types/customers/CustomersCardType";
import { PublicsOrdersPricesType } from "@/types/publics/orders/PricesType";
import { PublicsOrdersResultType } from "@/types/publics/orders/ResultType";
import { SharedGeneralsSelectOptionType } from "@/types/shared/generals/SharedGeneralsSelectOptionType";
import { SharedGeneralsSelectOptionsType } from "@/types/shared/generals/SharedGeneralsSelectOptionsType";
import { ShippingCarrierType } from "@/types/shared/generals/ShippingCarrierType";
import { ShippingCarrierOptionType } from "@/types/shared/generals/ShippingCarrierOptionType";
import { UrlType } from "@/types/UrlType";
import { OrderSettingType } from "@/types/OrderSettingType";
import { OfferType } from "@/types/publics/orders/OfferType";
import { VariantSelectedInput } from "@/types/input/OfferInput";
import { CARD_REQUIRED_PAYMENT_METHOD_CODES } from "@/helpers/constants";

import _ from "lodash";

const CARD_REQUIRED_PAYMENT_METHOD_CODES = [
  "payjp",
  "gmo_creditcard_one_time",
  "sbps_creditcard_one_time",
  "paygent_creditcard_one_time",
];
const SHIPPING_CARRIER_REQUIRED_BASES = ["product"];
const CART_SUBMITTED_ATTRIBUTES = [
  "productsData",
  "billingAddressData",
  "shippingAddressData",
  "customerData",
  "paymentData",
  "urlData",
  "shippingCarrierId",
  "specifyDeliveryDate",
  "subsOrderData",
];
const CART_UNSUBMITTED_ATTRIBUTES = [
  "urlData.productUpsellId",
  "paymentData.isAddNewCard",
  "paymentData.card",
];
const CUSTOMER_NONLOGIN_ATTRIBUTES = [
  "customerData.emailConfirmation",
  "customerData.email",
  "customerData.password",
  "customerData.passwordConfirmation",
];
const CART_PROCESS_ACTIONS = ["lp_to_confirm", "back_to_correct_input"];

type State = {
  urlInfo: UrlType;
  cart: CartInput;
  orderInfo: PublicsOrdersPricesType | null;
  shippingAddresses: CustomersShippingAddressType[];
  prefectures: SharedGeneralsSelectOptionType[];
  sexes: SharedGeneralsSelectOptionType[];
  dateOfWeekJp: SharedGeneralsSelectOptionType[];
  shippingCarrierOptions: any[];
  shippingCarrierOptionsCvUpsell: any;
  shippingCarrierOptionsXsell: any[];
  shippingCarrierDefault: any[];
  payjpCards: CustomersPayjpCardType[];
  gmoPgCards: CustomersGmoPgCardType[];
  softbankCards: CustomersCardType[];
  paygentCards: CustomersGmoPgCardType[];
  offerData: any;
  preventiveProductsData: ProductInput[];
  deliveryTimesOptions: any;
  deliveryDateAtOptions: any[];
  preventiveSubsOrderData: SubsOrderDataInput;
  preventiveSpecifyDeliveryDate: SpecifyDeliveryDateInput;
  preventiveShippingCarrierId: number | null;
  cartProps: any;
  submitWithNewCard: any;
  currentMode: string;
  currentBaseUrl: string;
  lastPage: string;
  amazonBuyerData: any;
  checkoutSessionSignature: string;
  orderNumberCvConfirm: string;
};

export const useCartStore = defineStore({
  id: "cart",

  state: (): State => ({
    urlInfo: {
      baseUrl: "",
      upsell: false,
      products: [],
      availablePaymentMethodOptions: [],
      availablePaymentMethods: [],
      productQuantityDisplay: false,
      deliveryCompanyDisplay: false,
      confirmEmailDisplay: false,
      passwordDisplay: false,
      confirmPasswordDisplay: false,
      generalVariantDisplay: false,
      ownVariantDisplay: false,
      caution02Display: false,
      cautionDisplay: false,
      normalProductAllowScheduleDeliveryDate: false,
      normalProductAllowScheduleDeliveryTime: false,
      normalProductShowScheduledDeliveryDates: false,
      normalProductAllowNilInDeliveryDate: false,
      subsProductAllowScheduleDeliveryDate: false,
      subsProductAllowScheduleDeliveryTime: false,
      subsProductShowScheduledDeliveryDates: false,
      subsProductAllowNilInDeliveryDate: false,
      subsProductAllowScheduleDeliveryCycleByDate: false,
      subsProductAllowScheduleDeliveryCycleByDayOfWeek: false,
      subsProductAllowScheduleDeliveryCycleByTerm: false,
      buyMultipleVariant: false,
      productsUrls: [],
      recurringOptions: {
        paymentScheduleOptions: [],
        dateOptions: [],
        termOptions: [],
      },
    },
    cart: {
      productsData: [
        {
          id: null,
          quantity: 1,
          isRecurring: false,
          variantData: [
            {
              variantId: null,
              quantity: null,
              valueOptions: [
                {
                  id: null,
                  value: null,
                },
              ],
            },
          ],
        },
      ],
      billingAddressData: {
        name01: "",
        name02: "",
        kana01: "",
        kana02: "",
        zip01: "",
        zip02: "",
        prefectureId: "",
        addr01: "",
        addr02: "",
        tel01: "",
        tel02: "",
        tel03: "",
      },
      shippingAddressData: {
        sameWithBillingAddress: true,
      },
      customerData: {
        email: "",
        emailConfirmation: "",
        password: "",
        passwordConfirmation: "",
        sexId: null,
        birthday: null,
        optin: false,
      },
      paymentData: {
        isAddNewCard: false,
        paymentMethodCode: "",
        cardToken: "",
        cardTokenKey: "",
        sourceId: "",
        card: {
          cardNumber: "",
          cardName: "",
          cardExpiry: "",
          cardCvc: "",
        },
      },
      urlData: {
        baseUrl: "",
        upsellBack: false,
        upsellNormal: false,
        productUpsellId: null,
      },
      shippingCarrierId: null,
      specifyDeliveryDate: {
        scheduledDeliveryTime: "",
        scheduledToBeDeliveredAt: null,
      },
      subsOrderData: {
        paymentSchedule: "",
        scheduledToBeDeliveredEveryXMonth: null,
        scheduledToBeDeliveredOnXthDay: null,
        scheduledToBeDeliveredEveryXDay: null,
        scheduledToBeDeliveredEveryXDayOfWeek: null,
        scheduledToBeDeliveredOnXthDayOfWeek: null,
        scheduledToBeDeliveredAt: "",
      },
      termAccepted: false,
    },
    orderInfo: null,
    shippingAddresses: [],
    prefectures: [],
    sexes: [],
    dateOfWeekJp: [],
    payjpCards: [],
    gmoPgCards: [],
    softbankCards: [],
    paygentCards: [],
    offerData: {},
    shippingCarrierOptions: [],
    shippingCarrierOptionsCvUpsell: {},
    shippingCarrierOptionsXsell: [],
    shippingCarrierDefault: [],
    preventiveProductsData: [],
    deliveryTimesOptions: [],
    deliveryDateAtOptions: [],
    preventiveSubsOrderData: {
      paymentSchedule: "",
      scheduledToBeDeliveredEveryXMonth: null,
      scheduledToBeDeliveredOnXthDay: null,
      scheduledToBeDeliveredEveryXDay: null,
      scheduledToBeDeliveredEveryXDayOfWeek: null,
      scheduledToBeDeliveredOnXthDayOfWeek: null,
      scheduledToBeDeliveredAt: "",
    },
    preventiveSpecifyDeliveryDate: {
      scheduledDeliveryTime: "",
      scheduledToBeDeliveredAt: null,
    },
    preventiveShippingCarrierId: null,
    cartProps: {},
    submitWithNewCard: null,
    currentMode: "normal",
    currentBaseUrl: "",
    lastPage: "lp",
    amazonBuyerData: null,
    checkoutSessionSignature: "",
    orderNumberCvConfirm: "",
  }),

  actions: {
    async fetchUrl(baseUrl: string) {
      const data = await useGraphql<UrlType>(cartQueries.url, {
        baseUrl,
        userAgent: window.navigator.userAgent,
      });

      this.urlInfo = data;
      this.cart.urlData.baseUrl = this.urlInfo.baseUrl;
      if (!this.cart.paymentData.paymentMethodCode)
        this.setPaymentMethodCode(this.urlInfo.availablePaymentMethodOptions?.[0]?.value ?? "");
    },

    async deliveryTimes(shippingCarrierId: any) {
      const data = await useGraphql<ShippingCarrierOptionType>(cartQueries.deliveryTimes, {
        shippingCarrierId: shippingCarrierId,
      });
      this.deliveryTimesOptions = data;
    },

    async orderSetting(id: any) {
      const data = await useGraphql<OrderSettingType>(cartQueries.orderSetting, {
        prefectureId: id,
      });
      this.deliveryDateAtOptions = data.deliveryDateAtOptions;
    },

    async fetchCustomer() {
      const data = await useGraphql<CustomersCustomerType>(
        cartQueries.customer,
        {},
        { errorToasted: false },
      );
      _.assign(this.shippingAddresses, data.shippingAddresses);
      _.assign(this.cart.billingAddressData, data.billingAddress);
      this.cart.customerData.emailConfirmation = data.email;
      this.cart.customerData.email = data.email;
      this.cart.customerData.birthday = data.birthday;
      this.cart.customerData.sexId = data.sexId;
    },

    async fetchSelectOptions() {
      const data = await useGraphql<SharedGeneralsSelectOptionsType>(commonQueries.selectOptions, {
        keys: ["sexes", "prefectures", "day_of_week_jp_options"],
      });
      this.prefectures = data.prefectures;
      this.sexes = data.sexes;
      this.dateOfWeekJp = data.dayOfWeekJpOptions;
    },

    async fetchShippingCarrierOptions(payload: any, mode = "normal") {
      const data = await useGraphql<ShippingCarrierOptionType[]>(
        commonQueries.fetchShippingCarrierOptions,
        payload,
      );

      mode == "xsell"
        ? (this.shippingCarrierOptionsXsell = data)
        : (this.shippingCarrierOptions = data);
    },

    async fetchShippingCarrierDefault() {
      const data = await useGraphql<ShippingCarrierOptionType[]>(
        commonQueries.fetchShippingCarrierDefault,
      );
      this.shippingCarrierDefault = data;
    },

    async ordersPaymentAmazonPayGetBuyerInfo(buyerToken: string) {
      const data = await useGraphql(commonQueries.ordersPaymentAmazonPayGetBuyerInfo, {
        buyerToken,
      });

      this.amazonBuyerData = data;
    },

    async orderPaymentAmazonPayGetCreateCheckoutSessionSignature(
      addressDetails: any,
      amount: string,
      isRecurring: boolean,
    ) {
      try {
        const data = await useGraphql(
          commonQueries.orderPaymentAmazonPayGetCreateCheckoutSessionSignature,
          { addressDetails, amount, isRecurring },
        );
        this.checkoutSessionSignature = String(data);
      } catch (e) {
        console.log(e);
      }
    },

    async validateCart(upsellNormal = false) {
      if (upsellNormal) {
        this.cart.urlData.upsellNormal = true;
        this.cart.urlData.productUpsellId = this.cart.productsData[0].id;

        this.preventiveProductsData = this.cart.productsData;
        this.preventiveSubsOrderData = this.cart.subsOrderData;
        this.preventiveSpecifyDeliveryDate = this.cart.specifyDeliveryDate;
        this.preventiveShippingCarrierId = this.cart.shippingCarrierId;

        this.cart.subsOrderData = useOrderStore().$state.upSellSubsOrderData;
        this.cart.productsData = [useOrderStore().$state.upSellVariantData];
        this.cart.specifyDeliveryDate = useOrderStore().$state.upSellSpecifyDeliveryDate;
        this.cart.shippingCarrierId = useOrderStore().$state.upSellShippingCarrierId;
      }

      const data = await useGraphql<PublicsOrdersPricesType>(cartQueries.validateCart, {
        input: this.cartSubmitVariables,
      }).catch(() => {});

      if (!data) {
        this.cart.urlData.upsellNormal = false;

        this.cart.productsData = this.preventiveProductsData;
        this.cart.subsOrderData = this.preventiveSubsOrderData;
        this.cart.specifyDeliveryDate = this.preventiveSpecifyDeliveryDate;
        this.cart.shippingCarrierId = this.preventiveShippingCarrierId;

        this.cart.urlData.productUpsellId = null;

        if (upsellNormal) useGlobalStore().$state.keepBaseError = true;
        return false;
      }

      useGlobalStore().resetValidationBaseErrors();

      this.orderInfo = data;
      return data;
    },

    submitCart(amazonCheckoutSessionId: string | null = null) {
      useGlobalStore().resetValidationBaseErrors();

      return useGraphql<PublicsOrdersResultType>(
        cartQueries.submitCart,
        {
          userAgent: window.navigator.userAgent,
          input: this.cartSubmitVariables,
          amazonCheckoutSessionId: amazonCheckoutSessionId,
        },
        { toasted: true },
      );
    },
    fetchOffer(orderNumber: string) {
      return useGraphql<OfferType>(cartQueries.ordersOfferFetch, { orderNumber });
    },

    async validateOfferCvUpsell(
      orderNumber: string,
      variantId: number | null,
      subsOrderData: SubsOrderDataInput,
      specifyDeliveryDate: SpecifyDeliveryDateInput,
      shippingCarrierId: number,
    ) {
      useGlobalStore().resetValidationBaseErrors();

      const data = await useGraphql<PublicsOrdersPricesType>(
        cartQueries.ordersCvUpsellModificationValidate,
        {
          input: { orderNumber, variantId, subsOrderData, specifyDeliveryDate, shippingCarrierId },
        },
      );

      // TODO AZM
      this.orderNumberCvConfirm = orderNumber;
      this.currentMode = "upsell";
      return data;
    },

    async validateOfferCvXsell(
      orderNumber: string,
      variantData: VariantSelectedInput[],
      subsOrderData: SubsOrderDataInput,
      specifyDeliveryDate: SpecifyDeliveryDateInput,
      shippingCarrierId: number,
    ) {
      useGlobalStore().resetValidationBaseErrors();

      const data = await useGraphql<PublicsOrdersPricesType>(
        cartQueries.ordersCvXsellModificationValidate,
        {
          input: {
            orderNumber,
            variantData,
            subsOrderData,
            specifyDeliveryDate,
            shippingCarrierId,
          },
        },
      );

      // TODO AZM
      this.orderNumberCvConfirm = orderNumber;
      this.currentMode = "xsell";
      return data;
    },

    submitOfferCvUpsell(
      orderNumber: string,
      variantId: number | null,
      subsOrderData: SubsOrderDataInput,
      specifyDeliveryDate: SpecifyDeliveryDateInput,
      shippingCarrierId: number,
      amazonCheckoutSessionId: string | null,
    ) {
      useGlobalStore().resetValidationBaseErrors();

      return useGraphql(cartQueries.ordersCvUpsellModify, {
        input: {
          orderNumber,
          variantId,
          subsOrderData,
          specifyDeliveryDate,
          shippingCarrierId,
          amazonCheckoutSessionId,
        },
      });
    },
    submitOfferCvXsell(
      orderNumber: string,
      variantData: VariantSelectedInput[],
      subsOrderData: SubsOrderDataInput,
      specifyDeliveryDate: SpecifyDeliveryDateInput,
      shippingCarrierId: number,
      amazonCheckoutSessionId: string | null,
    ) {
      useGlobalStore().resetValidationBaseErrors();

      return useGraphql(cartQueries.ordersCvXsellModify, {
        input: {
          orderNumber,
          variantData,
          subsOrderData,
          specifyDeliveryDate,
          shippingCarrierId,
          amazonCheckoutSessionId,
        },
      });
    },
    setPaymentMethodCode(code: string) {
      this.cart.paymentData.paymentMethodCode = code;
    },

    async calculateSubsOrderEstimate(input: any) {
      const data = await useGraphql<any>(cartQueries.calculateSubsOrderEstimate, {
        input: input,
      });

      return data?.estimatedTime;
    },

    ordersPaymentAmountValidate(
      amount: String,
      paymentMethodCode: String,
      isRecurring: Boolean | any,
    ) {
      return useGraphql<any>(commonQueries.ordersPaymentAmountValidate, {
        amount,
        paymentMethodCode,
        isRecurring,
      });
    },

    setCartProps(payload: any) {
      this.cartProps = payload;
    },
  },

  getters: {
    cartSubmitVariables() {
      const omission = CART_UNSUBMITTED_ATTRIBUTES;

      if (useAuthStore().isLoggedIn) {
        omission.push(...CUSTOMER_NONLOGIN_ATTRIBUTES);
      }

      if (this.cart.paymentData.paymentMethodCode != "sbps_creditcard_one_time") {
        this.cart.paymentData = _.omit(this.cart.paymentData, "cardTokenKey") as any;
      }

      var cartSubmit = _.cloneDeep(this.cart);

      cartSubmit.productsData = _.map(cartSubmit.productsData, function (product) {
        return _.omit(product, "isRecurring");
      });

      return _.omit(_.pick(cartSubmit, CART_SUBMITTED_ATTRIBUTES), omission);
    },

    resetRequired:
      (state) =>
      (baseUrl = "") => {
        const globalStore = useGlobalStore();
        const keepError = globalStore.keepError;

        if (state.currentBaseUrl != baseUrl) return true;
        else if (keepError || state.lastPage == "confirm") return false;

        return true;
      },
  },

  persist: {
    key: "united-cart-local",
    storage: window.localStorage,
    paths: [
      "cart",
      "orderInfo",
      "shippingAddresses",
      "urlInfo",
      "offerData",
      "shippingCarrierDefault",
      "deliveryTimesOptions",
      "deliveryDateAtOptions",
      "preventiveProductsData",
      "preventiveSubsOrderData",
      "preventiveSpecifyDeliveryDate",
      "preventiveShippingCarrierId",
      "currentMode",
      "currentBaseUrl",
      "lastPage",
      "amazonBuyerData",
      "orderNumberCvConfirm",
    ],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}

export { CARD_REQUIRED_PAYMENT_METHOD_CODES, SHIPPING_CARRIER_REQUIRED_BASES };
