import useGraphql from "@/composables/useGraphql";
import { CustomersCustomerType } from "@/types/customers/CustomersCustomerType";
import { defineStore, acceptHMRUpdate } from "pinia";
import * as authQueries from "@/graphql/queries/customers/auth";

interface State {
  token: string | null;
  customer: CustomersCustomerType | null;
  originalUrl: string | null;
  customerExisted: boolean | null;
}

export const useAuthStore = defineStore({
  id: "auth",

  state: (): State => ({
    token: null,
    customer: null,
    originalUrl: null,
    customerExisted: null,
  }),

  getters: {
    isLoggedIn: (state) => !!state.token,
  },

  persist: {
    key: "united-cart-auth",
    storage: window.localStorage,
    paths: ["token", "originalUrl", "customerExisted"],
  },

  actions: {
    logout() {
      this.token = null;

      const currentDate = new Date();
      const nextMonth = new Date(currentDate);
      nextMonth.setDate(currentDate.getDate() - 1);
      document.cookie = `united-cart-shop-token=; expires=${nextMonth.toUTCString()}; path=/`;
    },

    setTokenAndCookie(token: string) {
      this.token = token;

      const currentDate = new Date();
      const nextMonth = new Date(currentDate);
      nextMonth.setDate(currentDate.getDate() + 30);
      document.cookie = `united-cart-shop-token=${token}; expires=${nextMonth.toUTCString()}; path=/`;
    },

    async fetchCustomerInfo() {
      const data = await useGraphql<CustomersCustomerType>(authQueries.self);
      this.customer = data;
    },

    clearOriginalUrl() {
      this.originalUrl = null;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
