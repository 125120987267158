import { defineStore, acceptHMRUpdate } from "pinia";

import useGraphql from "@/composables/useGraphql";
import * as cartQueries from "@/graphql/queries/cart";

import { SubsOrderDataInput, SpecifyDeliveryDateInput } from "@/types/input/CartInput";
import { OrderOfferType } from "@/types/OrderOfferType";
import { PageViewType } from "@/types/publics/orders/PageViewType";
import {
  ProductInput,
  VariantSelectedInput,
  CvUpSellVariantSelectedInput,
  UpsellVariantSelectedInput,
} from "@/types/input/OfferInput";
import { useAuthStore } from "@/stores/auth";

import moment from "moment";

type State = {
  productInfo: OrderOfferType;
  cvUpSellVariantData: CvUpSellVariantSelectedInput; // CvUpSell data
  xSellVariantInput: VariantSelectedInput[]; // XSell data
  upSellVariantData: UpsellVariantSelectedInput; // Upsell data
  upSellSubsOrderData: SubsOrderDataInput;
  upSellSpecifyDeliveryDate: SpecifyDeliveryDateInput;
  upSellShippingCarrierId: number | null;
  productVariantInput: ProductInput[];
  pageViewData: PageViewType[];
};

export const useOrderStore = defineStore({
  id: "order",

  state: (): State => ({
    productInfo: {
      cvUpsellProducts: [],
      cvXsellProducts: [],
      timeout: 0,
      paymentScheduleOptions: [],
      buyMultipleVariant: false,
      generalVariantDisplay: false,
      ownVariantDisplay: false,
    },
    cvUpSellVariantData: {
      productSelectedId: null,
      variantId: null,
      isRecurring: false,
    },
    upSellVariantData: {
      id: null,
      quantity: 1,
      isRecurring: false,
      variantData: [
        {
          variantId: null,
          quantity: null,
          valueOptions: [
            {
              id: null,
              value: null,
            },
          ],
        },
      ],
    },
    xSellVariantInput: [],
    productVariantInput: [],
    pageViewData: [],
    upSellSubsOrderData: {
      paymentSchedule: "",
      scheduledToBeDeliveredEveryXMonth: null,
      scheduledToBeDeliveredOnXthDay: null,
      scheduledToBeDeliveredEveryXDay: null,
      scheduledToBeDeliveredEveryXDayOfWeek: null,
      scheduledToBeDeliveredOnXthDayOfWeek: null,
      scheduledToBeDeliveredAt: "",
    },
    upSellSpecifyDeliveryDate: {
      scheduledDeliveryTime: "",
      scheduledToBeDeliveredAt: null,
    },
    upSellShippingCarrierId: null,
  }),

  actions: {
    async fetchOffer(orderNumber: string) {
      const data = await useGraphql<OrderOfferType>(cartQueries.ordersOfferFetch, { orderNumber });
      this.productInfo = data;

      if (data.cvUpsellProducts.length) {
        this.cvUpSellVariantData.productSelectedId = data.cvUpsellProducts[0].id;
        this.cvUpSellVariantData.isRecurring = data.cvUpsellProducts[0].isRecurring;

        const variant = data.cvUpsellProducts[0].variants.find((item) => item.isMaster);

        if (variant && !data.cvUpsellProducts[0].variantCombinations) {
          this.cvUpSellVariantData.variantId = variant.id;
        }
      }

      this.productVariantInput = data.cvXsellProducts.map((item) => {
        return {
          id: item.id,
          variantId: item.variantCombinations ? null : item.variantMasterId,
          count: 0,
          recurringOptions: item.recurringOptions,
          isRecurring: item.isRecurring,
          paymentSchedule: item.paymentSchedule,
          scheduledToBeDeliveredEveryXMonth: item.scheduledToBeDeliveredEveryXMonth,
          scheduledToBeDeliveredOnXthDay: item.scheduledToBeDeliveredOnXthDay,
          scheduledToBeDeliveredEveryXDay: item.scheduledToBeDeliveredEveryXDay,
          scheduledToBeDeliveredEveryXDayOfWeek: item.scheduledToBeDeliveredEveryXDayOfWeek,
          scheduledToBeDeliveredOnXthDayOfWeek: item.scheduledToBeDeliveredOnXthDayOfWeek,
        };
      });
    },

    createPageViewData(baseUrl: string) {
      let viewer;

      if (this.pageViewData && this.pageViewData.length) {
        const viewers = this.pageViewData.filter((item) => item.baseUrl == baseUrl);

        viewer = viewers[viewers.length - 1];
      } else this.pageViewData = [];

      if (viewer) {
        const viewAt = moment(viewer.viewAt);
        const expiredAt = viewAt.add(1, "hour");
        const currentTime = moment();

        if (currentTime.isBefore(expiredAt)) {
          return;
        }
      }

      this.resetPageView(baseUrl);
      const userAgent = navigator.userAgent;

      return useGraphql<any>(cartQueries.publicsAdvertisementsCreatePvAdvertisement, {
        input: {
          baseUrl,
          userAgent,
        },
      }).then((result) => {
        if (result.success) {
          const newItem = {
            baseUrl: baseUrl,
            viewAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          };
          this.pageViewData.push(newItem);
        }
      });
    },

    createCustomersAccessHistory(baseUrl: string) {
      const authStore = useAuthStore();

      if (!authStore.token) return;

      useGraphql<any>(
        cartQueries.customersAccessHistoriesCreate,
        {
          baseUrl,
        },
        { errorToasted: false },
      );
    },

    resetPageView(baseUrl: string) {
      this.pageViewData = this.pageViewData.filter((item: any) => item.baseUrl != baseUrl);
    },
  },

  persist: {
    key: "united-cart-order",
    storage: window.localStorage,
    paths: ["pageViewData"],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrderStore, import.meta.hot));
}
