import { useField, useForm } from "vee-validate";
import { CARD_REQUIRED_PAYMENT_METHOD_CODES } from "@/helpers/constants";

import * as yup from "yup";

import validateBirthday from "@/helpers/validators/birthday_validators";

yup.setLocale({
  mixed: {
    required: "必須項目です",
    notType(ref) {
      switch (ref.type) {
        case "number":
          return "整数を半角で入力してください";
        case "string":
          return "必須項目です";
      }
    },
  },
  string: {
    email: "メールアドレスが正しくありません",
    min(ref) {
      return `${ref.min}文字以上にしてください`;
    },
  },
});

const useCartValidation = (isLoggedIn, initialValues, urlInfo) =>
  useForm({
    validationSchema: yup.object({
      productsData: yup.array().of(
        yup.object({
          id: yup.string().required(),
          quantity: yup.number().required().positive(),
          isRecurring: yup.boolean().nullable(),
          variantData: yup.array().of(
            yup.object({
              variantId: yup.string().nullable(),
              quantity: yup.number().nullable(),
              valueOptions: yup.array().when("variantId", {
                is: (variantId) => !variantId,
                then: (schema) =>
                  schema.of(
                    yup.object({
                      id: yup.string().required(),
                      value: yup.string().required(),
                    }),
                  ),
                otherwise: (schema) =>
                  schema.of(
                    yup.object({
                      id: yup.string().nullable(),
                      value: yup.string().nullable(),
                    }),
                  ),
              }),
            }),
          ),
        }),
      ),
      billingAddressData: yup.object(addressDataSchemaFragment),
      shippingAddressData: yup.object({
        sameWithBillingAddress: yup.boolean(),
        id: yup.string().nullable(),
        name01: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) => schema.required(),
        }),
        name02: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) => schema.required(),
        }),
        kana01: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) =>
            schema.required().matches(/^([ァ-ンｧ-ﾝﾞﾟ]|ー)+$/, "カタカナで入力して下さい"),
        }),
        kana02: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) =>
            schema.required().matches(/^([ァ-ンｧ-ﾝﾞﾟ]|ー)+$/, "カタカナで入力して下さい"),
        }),
        zip01: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) =>
            schema.required().min(3).matches(/^\d+$/, "整数を半角で入力してください"),
        }),
        zip02: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) =>
            schema.required().min(4).matches(/^\d+$/, "整数を半角で入力してください"),
        }),
        prefectureId: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) => schema.nullable().required(),
        }),
        addr01: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) => schema.required(),
        }),
        addr02: yup.string().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) => schema.required(),
        }),
        tel01: yup.number().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) => schema.required(),
        }),
        tel02: yup.number().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) => schema.required(),
        }),
        tel03: yup.number().when(["sameWithBillingAddress", "id"], {
          is: (sameWithBillingAddress: any, id: any) => !sameWithBillingAddress && !id,
          then: (schema) => schema.required(),
        }),
      }),
      customerData: yup.object({
        email: isLoggedIn ? yup.string() : yup.string().required().email(),
        emailConfirmation:
          !isLoggedIn && urlInfo.confirmEmailDisplay
            ? yup
                .string()
                .required()
                .email()
                .oneOf([yup.ref("email")], "入力された値が一致しません")
            : yup.string(),

        password:
          !isLoggedIn && (urlInfo.passwordDisplay || urlInfo.confirmPasswordDisplay)
            ? yup
                .string()
                .required()
                .matches(
                  /^((?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])|(?=.*[a-z])(?=.*[!@#$&*])(?=.*[0-9])|(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])).+$/,
                  "小文字大文字を含めた8文字以上の英数字で入力してください",
                )
            : yup.string(),

        passwordConfirmation:
          !isLoggedIn && urlInfo.confirmPasswordDisplay
            ? yup
                .string()
                .required()
                .matches(
                  /^((?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])|(?=.*[a-z])(?=.*[!@#$&*])(?=.*[0-9])|(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])).+$/,
                  "小文字大文字を含めた8文字以上の英数字で入力してください",
                )
                .oneOf([yup.ref("password")], "入力された値が一致しません")
            : yup.string(),
        ...customerBasicInfoSchemaFragment,
      }),
      paymentData: yup.object({
        isAddNewCard: yup.boolean(),
        paymentMethodCode: yup.string().required(),
        cardToken: yup.string(),
        sourceId: yup.string().when(["paymentMethodCode", "isAddNewCard"], {
          is: (paymentMethodCode, isAddNewCard) =>
            CARD_REQUIRED_PAYMENT_METHOD_CODES.includes(paymentMethodCode) && !isAddNewCard,
          then: (schema) => schema.required(),
        }),
        card: yup.object().when(["isAddNewCard", "paymentMethodCode"], {
          is: (isAddNewCard, paymentMethodCode) => isAddNewCard && paymentMethodCode != "payjp",
          then: (schema) =>
            schema.shape({
              cardNumber: yup.string().required(),
              cardName: yup.string().required(),
              cardExpiry: yup.string().required(),
              cardCvc: yup.string().required(),
            }),
          otherwise: (schema) =>
            schema.shape({
              cardNumber: yup.string(),
              cardName: yup.string(),
              cardExpiry: yup.string(),
              cardCvc: yup.string(),
            }),
        }),
      }),
      urlData: yup.object({
        baseUrl: yup.string(),
        upsellBack: yup.boolean(),
        upsellNormal: yup.boolean(),
        productUpsellId: yup.number().nullable(),
      }),
      shippingCarrierId: yup.string().required(),
      specifyDeliveryDate: yup.object({
        scheduledDeliveryTime: yup.string().nullable(),
        scheduledToBeDeliveredAt: yup.string().nullable(),
      }),
      subsOrderData: yup.object({
        paymentSchedule: yup.string().nullable(),
        scheduledToBeDeliveredEveryXMonth: yup
          .number()
          .nullable()
          .when(["paymentSchedule"], {
            is: (paymentSchedule: any) => ["date", "day_of_week"].includes(paymentSchedule),
            then: (schema) => schema.required(),
          }),
        scheduledToBeDeliveredOnXthDay: yup
          .number()
          .nullable()
          .when(["paymentSchedule"], {
            is: (paymentSchedule: any) => paymentSchedule == "date",
            then: (schema) => schema.required(),
          }),
        scheduledToBeDeliveredEveryXDay: yup
          .number()
          .nullable()
          .when(["paymentSchedule"], {
            is: (paymentSchedule: any) => paymentSchedule == "term",
            then: (schema) => schema.required(),
          }),
        scheduledToBeDeliveredEveryXDayOfWeek: yup
          .number()
          .nullable()
          .when(["paymentSchedule"], {
            is: (paymentSchedule: any) => paymentSchedule == "day_of_week",
            then: (schema) => schema.required(),
          }),
        scheduledToBeDeliveredOnXthDayOfWeek: yup
          .number()
          .nullable()
          .when(["paymentSchedule"], {
            is: (paymentSchedule: any) => paymentSchedule == "day_of_week",
            then: (schema) => schema.required(),
          }),
        scheduledToBeDeliveredAt: yup.string().nullable(),
      }),
      termAccepted: yup
        .boolean()
        .oneOf([true], "商品の注文には、各種規約に同意していただく必要がございます。"),
    }),
    initialValues,
  });

const customerBasicInfoSchemaFragment = {
  sexId: yup.string().nullable().required(),
  birthday: yup
    .string()
    .test("validateBirthday", "生年月日は不正な値です", function (value) {
      return validateBirthday(value, 100);
    })
    .required(),
  optin: yup.boolean(),
};

const addressDataSchemaFragment = {
  name01: yup.string().required(),
  name02: yup.string().required(),
  kana01: yup
    .string()
    .required()
    .matches(/^([ァ-ンｧ-ﾝﾞﾟ]|ー)+$/, "カタカナで入力して下さい"),
  kana02: yup
    .string()
    .required()
    .matches(/^([ァ-ンｧ-ﾝﾞﾟ]|ー)+$/, "カタカナで入力して下さい"),
  zip01: yup.string().required().min(3).matches(/^\d+$/, "整数を半角で入力してください"),
  zip02: yup.string().required().min(4).matches(/^\d+$/, "整数を半角で入力してください"),
  prefectureId: yup.string().nullable().required(),
  addr01: yup.string().required(),
  addr02: yup.string().required(),
  tel01: yup.number().required(),
  tel02: yup.number().required(),
  tel03: yup.number().required(),
};

export default useCartValidation;

export { addressDataSchemaFragment, customerBasicInfoSchemaFragment };
